export const tabsContent = {
  title: "Get ready to take your subscription business to the next level with premium add-ons available in your Billsby account",
  backgroundColor: "black",
  cardLinks: [
    {
      url: "/pro/advanced-gateways",
      title: "Advanced gateway selection"
    },
    {
      url: "/pro/advanced-value-score/",
      title: "Advanced Value Score"
    },
    {
      url: "/pro/affiliate-scheme/",
      title: "Affiliate schemes"
    },
    {
      url: "/pro/cardless-trials/",
      title: "Cardless trial"
    },
    {
      url: "/pro/debt-collection/",
      title: "Debt collection"
    },
    {
      url: "/pro/membership-cards/",
      title: "Membership cards"
    },
    {
      url: "/pro/priority-support/",
      title: "Priority support"
    },
    {
      url: "/pro/quotes-bespoke-plans/",
      title: "Quotes and bespoke plans"
    },
    {
      url: "/pro/referral-program/",
      title: "Referral programs"
    },
    {
      url: "/pro/revenue-recognition/",
      title: "Revenue recognition"
    },
    {
      url: "/pro/whitelabel/",
      title: "White label"
    }
  ]
}