import React from 'react';
import ComingSoon from '../../components/coming-soon';
import Layout from "../../components/layout"
// import { Helmet } from 'react-helmet'
import SEO from "../../components/seo"
import ProTabs from '../../components/pro-tabs'
import {tabsContent} from "../../lib/pro-tabs-content"

const WhiteLabel = () => {
  const comingSoonDetails = {
    backgroundImage: require('../../images/pro-banner11@2x.jpg'),
    heading: "Engage customers with cardless trials whilst seeing them in Billsby",
    subheading: "If you don't require a card to try your product, but you still want to manage your freemium and free trial customers in Billsby - now you can. We'll even send out reminder emails as trials come to an end to capture the payment details and information that we need."
  }

  return(
    <Layout>
      <SEO 
        title="Cardless Trials | Billsby Pro | Take your subscription business to the next level" 
        description="Cardless Trials and other Billsby Pro features let you take your subscription business to the next level." 
        url="https://www.billsby.com/pro/cardless-trials"
      />
      
      <ComingSoon 
        backgroundImage={comingSoonDetails.backgroundImage}
        heading={comingSoonDetails.heading}
        subheading={comingSoonDetails.subheading} />
      <ProTabs content={tabsContent} />
    </Layout>
  )
}

export default WhiteLabel;