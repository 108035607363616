import React from 'react';
// import { Link } from 'gatsby'
import ScheduleADemoButton from '../components/schedule-a-demo-button'

const ComingSoon = ( props ) => {
  return (
    <div className="section-coming-soon" style={{ backgroundImage: `url(${props.backgroundImage})`}}>
      <div className="container">
        <div className="content-wrapper">
          <button className="adoric_roadmap coming-soon">COMING SOON</button>
          <h1 className="heading-1">{props.heading}</h1>
          <p className="subheading-1">{props.subheading}</p>
          <div className="btn-wrapper">
            <a href="https://app.billsby.com/registration" rel="noopener noreferrer" target='_blank' className="btn-orange">Sign up for free</a>
            <ScheduleADemoButton/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComingSoon;